import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";

export default class VehicleStringFormatting {
    public static engineSize(size: number): string {
        const val = StringFormatting.numberWithCommas(size);

        return VehicleStringFormatting.addSuffix(" cc", val);
    }

    public static generateVehicleBranchDetailsCustomHtml(branch: DealerBranchPublicInfo): string {
        if(branch == null) {
            return "";
        }

        return `<div class=\"vehicle-branch-details-custom\">
        <div class=\"branch-title\">${branch.name}</div>
        <div>${branch.addr1}</div>
        <div>${branch.addr2}</div>
        <div>${branch.town}</div>
        <div>${branch.county}</div>
        <div>${branch.postcode}</div>
        <div>United Kingdom</div>
      </div>`;
    }


    public static taxRate(taxRate: number | null): string {
        if(taxRate == null) { return StringFormatting.coerceNullishToBlank(taxRate); }

        const val = Number(taxRate).toLocaleString(undefined, { maximumFractionDigits: 0 });
        return `£${val}`;
    }

    public static mpg(mpg: number): string {
        const val = StringFormatting.numberWithCommas(mpg, "");

        return VehicleStringFormatting.addSuffix(" mpg", val);
    }

    public static co2(co2: number): string {
        const val = StringFormatting.numberWithCommas(co2, "");

        return (val != "") ? VehicleStringFormatting.addSuffix(" g/km", val) : "";
    }

    public static speed(speed: number): string {
        const val = StringFormatting.numberWithCommas(speed, "");

        return VehicleStringFormatting.addSuffix(" mph", val);
    }

    public static height(mm: number): string {
        return VehicleStringFormatting.millimeters(mm);
    }

    public static width(mm: number): string {
        return VehicleStringFormatting.millimeters(mm);
    }

    public static length_(mm: number): string {
        return VehicleStringFormatting.millimeters(mm);
    }

    public static acceleration(zeroToSixtyInSeconds: number): string {
        const val = StringFormatting.numberWithCommas(zeroToSixtyInSeconds, "");

        return VehicleStringFormatting.addSuffix(" seconds", val);
    }

    public static bhp(bhp: number): string {
        const val = StringFormatting.numberWithCommas(bhp, "");

        return VehicleStringFormatting.addSuffix(" bhp", val);
    }

    public static range_kwh(kwh: number): string {
        if (kwh <=0)
        {
            return '';
        }
        const val = StringFormatting.numberWithCommas(kwh, "");

        return VehicleStringFormatting.addSuffix(" kWh", val);
    }

    public static price(price: number, isSold: boolean, zeroPriceText: string = "POA", has_price_from: boolean= false): string {
        var val: string = (isSold !== true)
          ? (price > 0)
              ? `${(has_price_from ? "From " : "")} £${Number(price).toLocaleString(undefined, { maximumFractionDigits: 0 })}`
              : zeroPriceText
            : "SOLD";

        return val;
    }

    public static listPrice(price: number, isSold: boolean, zeroPriceText: string = "POA"): string {
        var val: string = (isSold !== true)
          ? (price > 0)
              ? '<div class="field-name-list-price"><div class="label-inline">Was</div><div class="value">£' + Number(price).toLocaleString(undefined, { maximumFractionDigits: 0 }) + '</div></div>'
              : zeroPriceText
            : "";
        return val;
    }

    public static savingPrice(price: number, isSold: boolean, zeroPriceText: string = "POA"): string {
        var val: string = (isSold !== true)
          ? (price > 0)
              ? '<div class="field-name-discount"><div class="label-inline">Saving</div><div class="value">£' + Number(price).toLocaleString(undefined, { maximumFractionDigits: 0 }) + '</div></div>'
              : zeroPriceText
            : "";
        return val;
    }

    public static sash(sash: string): string {
        var val: string = (sash !== null && sash !== 'None' && typeof sash !== 'undefined' && sash !== "")
            ? "<div class=\"field-name-field-sash\">" + sash + "</div>"
            : "";

        return val;
    }

    public static vatExempt(vatExempt: boolean): string {
        const val: string = (vatExempt == true)
             ? "<div class='field-name-field-vehicle-vat'>No VAT</div>"
             : "";

        return val;
    }

    public static vatExcluded(vatExcluded: boolean): string {
        const val: string = (vatExcluded == true)
            ? "<div class='field-name-field-vehicle-vat'>+ VAT</div>"
            : "";

        return val;
    }

    public static millimeters(mm: number): string {
        const val = StringFormatting.numberWithCommas(mm, "");

        return VehicleStringFormatting.addSuffix(" mm", val);
    }

    public static kilogrammes(kg: number): string {
        const val = StringFormatting.numberWithCommas(kg, "");

        return VehicleStringFormatting.addSuffix(" kg", val);
    }

    public static getVideoHtml(videoId: string): string {
        return `<div class="video-youtube"><iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
    }

    private static addSuffix(suffix: string, val: string) {
        return (val != null && val != undefined && val.length) ? `${val}${suffix}` : "";
    }
    public static sourceAdvert(sourceAdvertID: string): string {
        return sourceAdvertID;
    }
    public static branchID(branchID: number): string {

        let formattedNumber = branchID.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        // const val =  StringFormatting.numberToString(formattedNumber);

        return formattedNumber;
    }
}
